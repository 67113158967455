import React from "react"
import Container from "@ecom/ui/components/Container"
import * as styles from "./moreBenefits.module.scss"

import Img1 from "./Img1"
import Img2 from "./Img2"

interface MoreBenefitsProps {
  installmentMonth?: number
  orderNum?: string
}

export const MoreBenefits = ({ installmentMonth = 10, orderNum }: MoreBenefitsProps) => (
  <section className={styles.section} data-exclude={orderNum}>
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.first_block}>
          <div className={styles.text_container}>
            <h3 className={styles.title}>Преимущества карты с&nbsp;подпиской «Халва.Десятка»</h3>
            <ul className={styles.list}>
              <li>увеличенная рассрочка до {installmentMonth} месяцев у партнеров</li>
              <li>кэшбэк до 10%</li>
              <li>До 17% на остаток собственных средств</li>
              <li>СМС и переводы без комиссии</li>
            </ul>
          </div>
          <Img1 alt="Халва 10" />
        </div>
        <div className={styles.second_block}>
          <div className={styles.text_container2}>
            <h3 className={styles.title}>Партнеры и акции</h3>
            <p>Огромный выбор маркетплейсов для любых целей</p>
            <p>Более 250 000 магазинов-партнеров по всей России</p>
          </div>
          <Img2 alt="Партнеры и акции" className={styles.img} />
        </div>
      </div>
    </Container>
  </section>
)
