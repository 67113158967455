import React from "react"
import type { ReactNode } from "react"

export type CashbackItem = {
  title: ReactNode
  hint: ReactNode
}

export const DEFAULT_ITEMS: [CashbackItem, CashbackItem, CashbackItem] = [
  {
    title: (
      <>
        До 10% <br />с подпиской
      </>
    ),
    hint: (
      <>
        Получайте повышенный кэшбэк до&nbsp;10% на покупки у партнеров на сумму свыше 50 000 рублей
      </>
    ),
  },
  {
    title: (
      <>
        От 2 до 6% у&nbsp;партнеров <br /> без подписки
      </>
    ),
    hint: (
      <>
        Покупайте в магазинах-партнерах и получайте выгодный кэшбэк:
        <br /> — до 6% за товар дороже 10 000 рублей,
        <br /> — от 2 до 4%, если сумма покупки не превышает 9999 рублей.
      </>
    ),
  },
  {
    title: "1% за покупки не у&nbsp;партнеров от 1000 руб. и оплату услуг в приложении",
    hint: (
      <>
        17% в первые 3 мес. после выдачи карты (далее 8,5%). С подпиской «Халва.Десятка», если
        сделано от 5 любых покупок за месяц на общую сумму не менее 10 000 руб.
      </>
    ),
  },
]
