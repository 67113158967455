import React from "react"
import Phone from "../Phone"

import * as styles from "./rightSection.module.scss"

export const RightSection = () => (
  <div className={styles.container}>
    <div className={styles.right}>
      <div>
        <Phone />
      </div>
    </div>
  </div>
)
