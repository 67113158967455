import React from "react"

import Container from "@ecom/ui/components/Container"
import MobImg from "./MobImg"
import Img from "./Img"

import * as styles from "./appSovcombank.module.scss"

type AppSovcombankProps = {
  orderNum?: string
}

export const AppSovcombank = ({ orderNum }: AppSovcombankProps) => (
  <section className={styles.section} data-exclude={orderNum}>
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.textBlock}>
          <h2 className={styles.head}>Нет приложения?</h2>
          <p className={styles.text}>
            Тогда скорее переходите по{" "}
            <a href="https://pwa.sovcombank.ru/" target="_blank" rel="noreferrer">
              ссылке
            </a>{" "}
            и узнайте, как установить его на ваше устройство!
          </p>
          <p>
            Остались вопросы – смотрите{" "}
            <a
              href="https://sovcombank.ru/pages/videoinstruktsiJI"
              target="_blank"
              rel="noreferrer"
            >
              видеоинструкции
            </a>{" "}
            на нашем сайте!
          </p>
        </div>
        <MobImg alt="app" className={styles.mobImg} />
        <Img alt="app" className={styles.img} />
      </div>
    </Container>
  </section>
)
