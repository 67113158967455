import {
  CashBackIcon,
  RemainedIcon,
  TransferIcon,
  InstallmentIcon,
  ServiceIcon,
  WithDrawingIcon,
} from "./icons"

export const ICONS: { [key: string]: () => JSX.Element } = {
  CashBackIcon,
  RemainedIcon,
  TransferIcon,
  InstallmentIcon,
  ServiceIcon,
  WithDrawingIcon,
}

export const ITEMS = [
  {
    icon: "InstallmentIcon",
    title: "Рассрочка <br/>0% до 10 мес",
  },
  {
    icon: "CashBackIcon",
    title: "Кэшбэк <br/>до 10%",
  },
  {
    icon: "RemainedIcon",
    title: "До 17% доход <br/>на остаток",
  },
  {
    icon: "TransferIcon",
    title: "Бесплатные <br/>переводы",
  },
  {
    icon: "ServiceIcon",
    title: "Бесплатное  <br/>обслуживание",
  },
  {
    icon: "WithDrawingIcon",
    title: "Бесплатное снятие наличных",
  },
]
