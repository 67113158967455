// extracted by mini-css-extract-plugin
export var bannerShow = "banner-module--bannerShow--Rqr61";
export var btn = "banner-module--btn--5x6xu";
export var btnBlock = "banner-module--btnBlock--Jj-HD";
export var container = "banner-module--container--RVBGS";
export var darkColor = "banner-module--darkColor--R6--A";
export var hideMobBlock = "banner-module--hideMobBlock--FIl84";
export var img = "banner-module--img--R7KS9";
export var mobBtn = "banner-module--mobBtn--6EMQR";
export var secondContainer = "banner-module--secondContainer--Zhpnc";
export var text = "banner-module--text--mjO5g";