import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import type { GatsbyImageProps } from "gatsby-plugin-image"

const imgQuery = graphql`
  {
    card: file(relativePath: { eq: "cardbenefits/card.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`

export function Img(props: Omit<GatsbyImageProps, "image">) {
  const data = useStaticQuery(imgQuery)

  return <GatsbyImage {...props} image={getImage(data.card)!} />
}
