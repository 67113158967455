// extracted by mini-css-extract-plugin
export var br = "moreBenefits-module--br--4SBbk";
export var first_block = "moreBenefits-module--first_block--eAwfL";
export var img = "moreBenefits-module--img--4yq+2";
export var list = "moreBenefits-module--list--s7A3L";
export var second_block = "moreBenefits-module--second_block--ebtpA";
export var section = "moreBenefits-module--section--cqiuQ";
export var text_container = "moreBenefits-module--text_container--cupw+";
export var text_container2 = "moreBenefits-module--text_container2--XdrlY";
export var title = "moreBenefits-module--title--f7NK3";
export var wrapper = "moreBenefits-module--wrapper--l7GIi";