import React from "react"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import * as stylesOrig from "./phone.module.scss"

export type PhoneProps = {
  phones?: string[]
  styles?: any
}

const Phone = ({ phones = ["8 (800) 100 00 06"], styles = stylesOrig }: PhoneProps) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const dataLayerData = {
      name: e.currentTarget.textContent!,
      actionType: "call",
    }
    pushToDataLayer(dataLayerData)
  }

  return (
    <div className={styles.container}>
      {phones?.map((phone, i) => (
        <a key={i} href={`tel:${phone}`} onClick={handleClick}>
          {phone}
        </a>
      ))}
      Для звонков по России бесплатно
    </div>
  )
}
export default Phone
