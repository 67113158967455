import React, { ReactNode, useEffect, useState } from "react"
import clsx from "clsx"
import cn from "classnames"

import Container from "@ecom/ui/components/Container"

import Img from "./Img"

import * as styles from "./banner.module.scss"
import { useWindowSize } from "../../../helpers/useWindowSize"

type BannerProps = {
  darkColor?: boolean
  subtitle?: ReactNode
  orderNum?: string
}

export function Banner({
  darkColor,
  subtitle = <>с зарплатной картой «Халва»</>,
  orderNum,
}: BannerProps) {
  const [bannerShow, setBannerShow] = useState(false)
  const [, height] = useWindowSize()

  useEffect(() => {
    document.documentElement.style.setProperty("--height", `${height * 0.01}px`)
    document.documentElement.style.setProperty("--opacity", "1")

    setBannerShow(true)
  }, [height])

  return (
    <>
      <section data-exclude={orderNum}>
        <Container className={cn(styles.container, darkColor && styles.darkColor)}>
          <div
            className={
              bannerShow ? clsx(styles.secondContainer, styles.bannerShow) : styles.secondContainer
            }
          >
            <Img alt="bg image" className={styles.img} />
            <div className={styles.text}>
              <h1>
                <span>Ваша выгода</span>
                <br />
                {subtitle}
              </h1>
              <p>
                — Кэшбэк 1% при оплате ЖКХ
                <br />
                — 17% на остаток по карте
                <br />
                — Кэшбэк до 10% с подпиской
                <br />— +1% к ставке по вкладу
              </p>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}
