import React from "react"

import Header from "@ecom/ui/components/Header/HeaderContainer"
import { BlackHalva } from "./Logo/BlackHalva"
import { RightSection } from "./RightSection/RightSection"

export type HeaderProps = {
  children?: React.ReactNode
}

export const ZarplatnayaCartaHeader = ({ children }: HeaderProps) => {
  const onLogoClick = () => {
    window.open("https://halvacard.ru/", "_blank")
  }

  return (
    <Header
      withButton={false}
      withPhone
      right={<RightSection />}
      onLogoClick={onLogoClick}
      buttonText
      noShadow
      Logo={BlackHalva}
    >
      {children}
    </Header>
  )
}
