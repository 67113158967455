import React, { useEffect, useReducer } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { ZarplatnayaCartaHeader } from "../components/Headers/ZarplatnayaCartaHeader"

import { Banner } from "../components/Banners/ZarplatnayKartaHalva"
import { CardWithMaxBenefits } from "../components/ZarplatnayKartaBenefits"
import { CashbackHalva } from "../components/ZarplatnayKartaHalva"
import CardBenefits from "../components/ZarplatnayaKartaBenefit/CardBenefits"
import { AppSovcombank } from "../components/AppSovcombank"
import { NewFooter } from "../components/NewFooter"
import { MoreBenefits } from "../components/MoreBenefits"

import { getPageData } from "../helpers/getPageData"

import { PageData } from "../interfaces/pageProps"
import { sendScrollEventToDataLayer } from "../helpers/sendScrollEventToDataLayerPkw"

const IDS = ["formPkw", "partners", "questions", "footer"]

type ObserverType = { formPkw: boolean; partners: boolean; questions: boolean; footer: boolean }

function observerReducer(state: ObserverType, action: any) {
  if (IDS.includes(action.id)) {
    return { ...state, [action.id]: action.value }
  }
  throw new Error()
}

const initialObserverState: ObserverType = {
  formPkw: false,
  partners: false,
  questions: false,
  footer: false,
}

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const [observerState, observerDispatch] = useReducer(observerReducer, initialObserverState)

  const callback = (entries: IntersectionObserverEntry[]) => {
    for (let index = 0; index < entries.length; index += 1) {
      const element = entries[index]
      const { id } = element.target
      observerDispatch({ id, value: element.isIntersecting })
    }
  }

  useEffect(() => {
    Object.keys(observerState).forEach((key) => {
      if (observerState[key as keyof typeof initialObserverState]) {
        sendScrollEventToDataLayer(key)
      }
    })
  }, [observerState])

  useEffect(() => {
    const isSupported =
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    let observer: IntersectionObserver

    if (isSupported) {
      observer = new IntersectionObserver(callback)

      IDS.forEach((id) => {
        const element = document.getElementById(id)
        if (element) {
          observer.observe(element)
        }
      })
    }

    return () => {
      if (isSupported) {
        IDS.forEach((id) => {
          const element = document.getElementById(id)
          if (element) {
            observer.unobserve(element)
          }
        })
      }
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <ZarplatnayaCartaHeader />
      <Banner orderNum="2" />
      <CardWithMaxBenefits orderNum="3" />
      <CashbackHalva clickInformerDataLayer orderNum="4" />
      <CardBenefits orderNum="5" />
      <MoreBenefits orderNum="6" />
      <AppSovcombank orderNum="7" />
      <NewFooter ligal={ligal} orderNum="8" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/zarplatnaya-karta-halva/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
